import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";
import Sidenav from "./components/SideNav";
import { useTranslation } from 'react-i18next';
import PlaceholderCard from "examples/Cards/PlaceholderCard";

function Sachbearbeitung() {
    const { t } = useTranslation();
    return (
        <>
            <SoftBox>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sm={6} lg={3}>
                        <Sidenav />
                    </Grid>
                    <Grid container spacing={2} lg={9} xs={12} mt={3} pl={2}>
                        <SoftBox pl={4} width="100%">
                            <SoftTypography fontSize="20px" fontWeight="medium">
                                {t('Sachbearbeitung')}
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox mt={3} height="18.5rem">
                            <PlaceholderCard
                                icon={""}
                                title={{ variant: "h6", text: t("Videos coming soon.") }}
                                hasBorder
                            />
                        </SoftBox>

                        {/*
                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <Card>
                                    <SoftBox p={2}>
                                    <SoftTypography fontSize="15px">Ablauf VKB</SoftTypography>
                                    <SoftBox mt={1} textAlign="center" borderRadius="15%">
                                        <SoftBox
                                        as="iframe"
                                        width="95%"
                                        height="300"
                                        src="https://player.vimeo.com/video/822268491?h=95c3fd8c04"
                                        title="Ablauf VKB"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                        allowfullscreen
                                        borderRadius="3%"
                                        />
                                    </SoftBox>
                                    </SoftBox>
                                </Card>
                            </Grid>
                        */}

                    </Grid>
                </Grid>
            </SoftBox>
        </>
    );
}

export default Sachbearbeitung;
