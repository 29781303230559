import { React, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
import SoftTypography from "components/SoftTypography";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import SidenavCard from "components/SidenavCard";

function Sidebar(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [lastMessage, setLastMessage] = useState([]);
    const [authId, setAuthId] = useState('');
    const [nowDrop, setNowDrop] = useState(window.location.href.split('/')[3]);
    let navClassName = "nav-link";
    let activeClassName = "nav-link active";
    let dropdownClass = "collapse";
    let dropdownClassShow = "collapse show";

    useEffect(() => {
        callFetch("chatuser-sidebar", "GET", []).then((res) => {
            //   console.log(res.lastMessage); 
            //   console.log(res.authId); 
            setLastMessage(res.lastMessage);
            setAuthId(res.authId);
          });
    }, []);

    useEffect(() => {
        const g_sidenav_pinned = document.getElementsByClassName("g-sidenav-pinned");
        if(g_sidenav_pinned.length){
            document.getElementById("iconSidenav").click();
        }
    }, [window.location.href])

return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-white" id="sidenav-main">
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav" />
                <a className="navbar-brand m-0" href="/">
                    <img src="/assets/img/logo.svg" className="navbar-brand-img h-100" alt="main_logo" />
                </a>
            </div>
            <hr className="horizontal dark mt-0" />
            <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <NavLink to="/dashboard" onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                            <span className="nav-link-text ms-1">{t('Dashboard')}</span>
                        </NavLink>
                    </li>
                    
                    {Cookies.get('permissions').indexOf("Message") !== -1 ? (
                    <li className="nav-item">
                        {lastMessage && (
                            <NavLink id="chat-link" to={lastMessage.sender_id == authId ? '/chat/message/'+lastMessage.receiver_id : '/chat/message/'+lastMessage.sender_id} onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-envelope icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                                <span className="nav-link-text ms-1">{t('Message')}</span>
                            </NavLink>
                        )}
                        {!lastMessage && (
                            <NavLink id="chat-link" to='/chat' onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-envelope icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                                <span className="nav-link-text ms-1">{t('Message')}</span>
                            </NavLink>
                        )}
                    </li>
                    ) : <></>}


                    <Divider />                    
                    <SoftTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={4}
                        mt={0}
                        mb={1}
                        ml={1}
                    >
                        {t('Crm')}
                    </SoftTypography>

                    {Cookies.get('permissions').indexOf("project-read") !== -1 ? (
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#project-management" className={nowDrop === 'project-management' ? activeClassName : navClassName} aria-controls="project-management" role="button" aria-expanded={nowDrop === 'project-management'}>
                            <i className="fa-solid fa-briefcase icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Project Management')}</span>
                        </a>
                        <div className={nowDrop === 'project-management' ? dropdownClassShow : dropdownClass} id="project-management">
                            <ul className="nav ms-4 ps-3">
                                {Cookies.get('permissions').indexOf("project-create") !== -1 ? (
                                    <li className="nav-item">
                                        <NavLink to="/project-management/projectscreate" onClick={(e) => setNowDrop('project-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('New Project')}</span>
                                        </NavLink>
                                    </li>
                                ) : <></>}
                                {Cookies.get('permissions').indexOf("project-read") !== -1 ? (
                                    <li className="nav-item">
                                        <NavLink to="/project-management/projects" onClick={(e) => setNowDrop('project-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('All Projects')}</span>
                                        </NavLink>
                                    </li>
                                ) : <></>}
                            </ul>
                        </div>
                    </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("customer-read") !== -1 ||
                        Cookies.get('permissions').indexOf("quotation-read") !== -1 ||
                        Cookies.get('permissions').indexOf("order-read") !== -1 
                        ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#customer-management" className={nowDrop === 'customer-management' ? activeClassName : navClassName} aria-controls="customer-management" role="button" aria-expanded={nowDrop === 'customer-management'}>
                                <i className="fa-solid fa-user-group icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Sales & Distribution')}</span>
                            </a>
                            <div className={nowDrop === 'customer-management' ? dropdownClassShow : dropdownClass} id="customer-management">
                                <ul className="nav ms-4 ps-3">
                                    <li className="nav-item">
                                        <NavLink to="/customer-management/overview" onClick={(e) => setNowDrop('customer-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Overview')}</span>
                                        </NavLink>
                                    </li>
                                    {Cookies.get('permissions').indexOf("customer-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/customer-management/customers" onClick={(e) => setNowDrop('customer-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Customers')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("quotation-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/customer-management/quotations" onClick={(e) => setNowDrop('customer-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Quotations')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}
                                    {Cookies.get('permissions').indexOf("order-read") !== -1 ? (
    				                    <li className="nav-item">
                                            <NavLink to="/customer-management/orders" onClick={(e) => setNowDrop('customer-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Orders')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("supplier-read") !== -1 || 
                        Cookies.get('permissions').indexOf("product-read") !== -1 ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#product-management" className={nowDrop === 'product-management' ? activeClassName : navClassName} aria-controls="product-management" role="button" aria-expanded={nowDrop === 'product-management'}>
                                <i className="fa-solid fa-truck icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Supply Chain')}</span>
                            </a>
                            <div className={nowDrop === 'product-management' ? dropdownClassShow : dropdownClass} id="product-management">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("supplier-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/product-management/suppliers" onClick={(e) => setNowDrop('product-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Suppliers')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("product-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/product-management/products" onClick={(e) => setNowDrop('product-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Products')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>
                    ) : <></>}  

                    {Cookies.get('permissions').indexOf("form-read") !== -1 ? (
                        <>
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#checklist" className={nowDrop === 'checklist' ? activeClassName : navClassName} aria-controls="customer-management" role="button" aria-expanded={nowDrop === 'customer-management'}>
                                <i className="fa-solid fa-file icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Checklist & Forms')}</span>
                            </a>
                            <div className={nowDrop === 'checklist' ? dropdownClassShow : dropdownClass} id="checklist">
                                <ul className="nav ms-4 ps-3">
                                    <li className="nav-item">
                                        <NavLink to="/form/active" onClick={(e) => setNowDrop('checklist')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Aktive')}</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/form/deactive" onClick={(e) => setNowDrop('checklist')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Entwürfe')}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        </>
                    ) : <></>} 



                    <Divider />                    
                    <SoftTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={4}
                        mt={0}
                        mb={1}
                        ml={1}
                    >
                        {t('Finance')}
                    </SoftTypography>
                    {Cookies.get('permissions').indexOf("invoice-read") !== -1 ? (
                            <li className="nav-item">
                                <a data-bs-toggle="collapse" href="#finance" className={nowDrop === 'finance' ? activeClassName : navClassName} aria-controls="finance" role="button" aria-expanded={nowDrop === 'finance'}>
                                    <i className="fa-solid fa-coins icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                    <span className="nav-link-text ms-1">{t('Finance')}</span>
                                </a>
                                <div className={nowDrop === 'finance' ? dropdownClassShow : dropdownClass} id="finance">
                                    <ul className="nav ms-4 ps-3">
                                        <li className="nav-item">
                                            <NavLink to="finance/invoice" onClick={(e) => setNowDrop('finance')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Invoice')}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        ):<></>
                    }

                    <Divider />                    
                    <SoftTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={4}
                        mt={0}
                        mb={1}
                        ml={1}
                    >
                        {t('HR')}
                    </SoftTypography>
                    {Cookies.get('permissions').indexOf("employee-read") !== -1 ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#hr" className={nowDrop === 'hr' ? activeClassName : navClassName} aria-controls="hr" role="button" aria-expanded={nowDrop === 'hr'}>
                                <i className="fa-solid fa-users icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Staff Management')}</span>
                            </a>
                            <div className={nowDrop === 'hr' ? dropdownClassShow : dropdownClass} id="hr">
                                <ul className="nav ms-4 ps-3">
                                    <li className="nav-item">
                                        <NavLink to="/human-resources/employees" onClick={(e) => setNowDrop('hr')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Employees')}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("workShop-read") !== -1 ||
                        Cookies.get('permissions').indexOf("student-read") !== -1 ||
                        Cookies.get('permissions').indexOf("seminar-read") !== -1 
                        ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#cheklist" className={nowDrop === 'cheklist' ? activeClassName : navClassName} aria-controls="cheklist" role="button" aria-expanded={nowDrop === 'cheklist'}>
                                <i className="fa-solid fa-graduation-cap icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Course Management')}</span>
                            </a>
                            <div className={nowDrop === 'cheklist' ? dropdownClassShow : dropdownClass} id="cheklist">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("workShop-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="course-management/workshops" onClick={(e) => setNowDrop('cheklist')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Work Shops')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("student-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="course-management/students" onClick={(e) => setNowDrop('cheklist')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Student List')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("seminar-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="course-management/seminars" onClick={(e) => setNowDrop('cheklist')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Seminar List')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>
                    ) : <></>}


                    <Divider />                    
                    <SoftTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={4}
                        mt={0}
                        mb={1}
                        ml={1}
                    >
                        {t('Settings')}
                    </SoftTypography>
                           

                    {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#user-settings" className={nowDrop === 'user-settings' ? activeClassName : navClassName} aria-controls="user-settings" role="button" aria-expanded={nowDrop === 'user-settings'}>
                                <i className="fa-solid fa-gear icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Settings')}</span>
                            </a>
                            <div className={nowDrop === 'user-settings' ? dropdownClassShow : dropdownClass} id="user-settings">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                                        <>
                                            <li className="nav-item">
                                                <NavLink to="/settings/roles" onClick={(e) => setNowDrop('user-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('Settings')}</span>
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <a data-bs-toggle="collapse" href="#email-settings" className={nowDrop === 'email-settings' ? activeClassName : navClassName} aria-controls="email-settings" role="button" aria-expanded={nowDrop === 'email-settings'}>
                                                <span className="nav-link-text">{t('Email Setup')}</span>
                                                </a>
                                                <div className={nowDrop === 'email-settings' ? dropdownClassShow : dropdownClass} id="email-settings">
                                                    <ul className="nav ms-3 ps-1                                                                  ">
                                                        {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                                                            <>
                                                                <li className="nav-item">
                                                                    <NavLink to="/settings/smtp/1/edit" onClick={(e) => setNowDrop('smptp-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                        <span className="sidenav-normal">{t('SMTP')}</span>
                                                                    </NavLink>
                                                                </li>

                                                                <li className="nav-item">
                                                                    <a data-bs-toggle="collapse" href="#emails-settings" className={nowDrop === 'email-settings' ? activeClassName : navClassName} aria-controls="emails-settings" role="button" aria-expanded={nowDrop === 'emails-settings'}>
                                                                        <span className="nav-link-text ms-1">{t('Emails')}</span>
                                                                    </a>
                                                                    <div className={nowDrop === 'emails-settings' ? dropdownClassShow : dropdownClass} id="emails-settings">
                                                                        <ul className="nav ms-1 ps-1">
                                                                            {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                                                                                <>
                                                                                    <li className="nav-item"><NavLink to="/settings/emails/angebot/1/edit" onClick={(e) => setNowDrop('angebot-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('Angebot')}</span></NavLink></li>
                                                                                    <li className="nav-item"><NavLink to="/settings/emails/order/1/edit" onClick={(e) => setNowDrop('order-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('Order')}</span></NavLink></li>
                                                                                    <li className="nav-item"><NavLink to="/settings/emails/invoice/1/edit" onClick={(e) => setNowDrop('invoice-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('Invoice')}</span></NavLink></li>
                                                                                </>
                                                                            ) : <></>}
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ) : <></>}
                                                    </ul>
                                                </div>
                                            </li>
                                        </>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>
                    ) : <></>}


                    <SoftBox pt={2} my={2} mx={2}>
                        <SidenavCard />
                    </SoftBox>
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
