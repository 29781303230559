import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch"; 
import Moment from 'react-moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"; 

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox"; 
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData"; 
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const CustomFiles = (props) => {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [dataa, setDataa] = useState([]);
    const [dachansicht, setDachansicht] = useState([]);
    
    
    useEffect(() => { 
        if(!props.project_id) return;

        callFetch("get-custom-uploaded-files/" + props.project_id, "GET", []).then((res) => { 
            // setDachansicht(res.order.dachansicht  && res.order.dachansicht != null && res.order.dachansicht != 'null' ? JSON.parse(res.order.dachansicht) : null);
            setDataa(res.order);
            setData(res.data); 
        });
    }, [props.project_id, props.refresh]); 
  return (
    <>
        <div className="row mb-5">
            <SoftBox p={3} className="order-processing">
                <Grid container spacing={3}>
                {data?
                    data?.map((item, index) => (  
                        <Grid item key={index}>
                            <a target="_blank" download href={process.env.REACT_APP_STORAGE_URL+'storage/attachments/'+item.file_path}>
                                <img style={{ width: '100%'}} src={process.env.REACT_APP_STORAGE_URL+'storage/attachments/'+item.file_path} />
                            </a>
                        </Grid>  
                    )) : <></>} 
                {dataa?
                    dataa?.map((item, index) => (  
                        <Grid item key={index}>
                            <a target="_blank" download href={process.env.REACT_APP_STORAGE_URL+'storage/attachments/'+item.attachment}>
                                <img style={{ width: '100%'}} src={process.env.REACT_APP_STORAGE_URL+'storage/attachments/'+item.attachment} />
                            </a>
                        </Grid>  
                    )) : <></>} 
                </Grid>
            </SoftBox>
        </div>
        <ToastContainer />
    </>
  )
}

export default CustomFiles