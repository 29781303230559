import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

function Edit() {
    let params = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [editDelay, setEditDelay] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if(!params.id) return;

        callFetch("support/" + params.id + "/edit", "GET", []).then((res) => {
            setData(res.data);
            setEditDelay(editDelay+1);
        });
    }, [params.id]);

    useEffect(() => {
        if(!editDelay) return;
        
        for (let [key, value] of Object.entries(data?.support)) {
            setValue(key, value);
        }        
    }, [editDelay]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("support/" + params.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/support' /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Edit Speicher')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />

                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>{t('Subject')} *</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("subject", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.subject && errors.subject.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>{t('Project')}</label>
                                    <select
                                        className="form-control"
                                        {...register("related_id", {
                                            required: false,
                                        })}
                                    >
                                        <option value="">--</option>
                                        {data?.projects && data.projects.map((project) => (
                                            <option key={project.id} value={project.id}>{project.id}</option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">{errors.department_id && errors.department_id.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>{t('Department')}</label>
                                    <select
                                        className="form-control"
                                        {...register("department_id", {
                                            required: false,
                                        })}
                                    >
                                        <option value="">--</option>
                                        {data?.departments && data.departments.map((department) => (
                                            <option key={department.id} value={department.id}>{department.name}</option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">{errors.department_id && errors.department_id.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>{t('Priority')} *</label>
                                    <select
                                        className="form-control"
                                        {...register("priority", {
                                            required: true,
                                        })}
                                        required
                                    >
                                        <option value="">--</option>
                                        <option value="High">High</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Low">Low</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.priority && errors.priority.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Description')}</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder={t('')}
                                        {...register("description")}></textarea>
                                    <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                </div>

                                <div className="col-12 mb-4">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
}

export default Edit;
