import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "./style.css"
import IndexTableCustomer from "./IndexTableCustomer";
import IndexTableSupplier from "./IndexTableSupplier";
import IndexTableProduct from "./IndexTableProduct";

const OutputModal = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState(t('Files Supported')+": XLSX");
    const [success, setSuccess] = useState(null);
         
  return (
    <>
        <div className="modal fade" id={props?.type+"outputModal"} tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{maxWidth: '98%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="outputtModalLabel">{t('Import')}</h5>
                        <button type="button" onClick={()=>{setSuccess(null);}} className="btn-close text-dark" id="outputModal-button-close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button> 
                    </div>
                    <div className="modal-body">
                        {props?.type == 'customer' ? (
                            <IndexTableCustomer filename={props.filename} type={props.type} />
                        ) : props?.type == 'supplier' ? (
                            <IndexTableSupplier filename={props.filename} type={props.type} />
                        ) : props?.type == 'product' ? (
                            <IndexTableProduct filename={props.filename} type={props.type} />
                        ): ''}                        
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default OutputModal